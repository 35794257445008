import Vue from 'vue';
export default class EmailService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }
    filtrarEmail(filtros, nr_pagina, nr_email_pagina) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        filtros.apresentante = this._store.getters.cdApresentante;
        filtros.pagina = nr_pagina;
        filtros.email_por_pagina = nr_email_pagina;

        return Vue.http.get(
            url + '/emails/api/emails',
            {
                params: filtros
            },
            {
                // headers: {
                //     Authorization: 'Bearer ' + this._store.getters.token
                // }
            }
        );
    }
    detalharEmail(email) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(url + `/emails/api/emails/${email.id}`, {
            params: {
                apresentante: this._store.getters.cdApresentante
            }
        });
    }
    salvarEmail(data) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/emails/api/emails';
        if (data.id) {
            let path = data.id;
            response = Vue.http.patch(url + '/' + path, data, {
                params: {
                    apresentante: this._store.getters.cdApresentante
                }
            });
        } else {
            response = Vue.http.post(url, data);
        }
        return response;
    }
    listarHistorico(tituloSelecionado) {
        return this._http.get('emails/retorno_enviados', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                ds_nosso_numero: tituloSelecionado.ds_nosso_numero
            }
        });
    }
    buscaLembretes() {
        return this._http.get('emails/lembretes/read', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante
            }
        });
    }

    salvaLembretes(diasSelecionados) {
        return this._http.put('emails/lembretes/update', {
            cd_apresentante: this._store.getters.cdApresentante,
            dias_para_envio: diasSelecionados
        });
    }

    criaLembretes(diasSelecionados) {
        return this._http.post('emails/lembretes/create', {
            cd_apresentante: this._store.getters.cdApresentante,
            dias_para_envio: diasSelecionados
        });
    }

    buscaNomeApresentante() {
        return this._http.get('apresentante/read', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante
            }
        });
    }

    criaNomeApresentante(nomeExibicao) {
        return this._http.post('apresentante/create', {
            cd_apresentante: this._store.getters.cdApresentante,
            nome_exibicao: nomeExibicao
        });
    }

    salvaNomeExibicaoApresentante(nomeSelecionado, contato) {
        return this._http.put('apresentante/update', {
            cd_apresentante: this._store.getters.cdApresentante,
            nome_exibicao: nomeSelecionado,
            link_contato: contato
        });
    }
}
